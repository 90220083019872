.summary-odyssee-swiper {
  .swiper-slide {
    width: 500px !important;
    @media screen and (max-width:600px) {
      width: 75vw !important;
    }

  }
  .swiper-pagination {
    .swiper-pagination-bullet {
      background: #FFFFFF;
    }
  }
  .swiper-button-prev {
    background: #FBF0E512;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    left: 30px;
    &::after {
      color: white;
      font-size: 14px;
    }
  }
  .swiper-button-next {
    background: #FBF0E512;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    right: 30px;
    &::after {
      color: white;
      font-size: 14px;
    }
  }
}


.summary-odyssee-step-swiper {
  .swiper-wrapper {
    //padding: 0 20px
  }
  .swiper-slide {
    //width: 380px !important;
    height: auto;
  }
  .swiper-pagination {
    .swiper-pagination-bullet {
      background: #FFFFFF;
    }
  }
  .swiper-button-prev {
    bottom: 0;
    top: unset;
    background: #FBF0E512;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    z-index: 11;
    &::after {
      color: white;
      font-size: 14px;
    }
  }
  .swiper-button-next {
    bottom: 0;
    top: unset;
    background: #FBF0E512;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    z-index: 11;
    &::after {
      color: white;
      font-size: 14px;
    }
  }
}
.odyssee-go-further-swiper {
  .swiper-wrapper {
    //padding: 0 20px
  }
  .swiper-slide {
    width: min(320px, 50vw) !important;
    height: auto;
  }
  .swiper-pagination {
    .swiper-pagination-bullet {
      background: #FFFFFF;
    }
  }
  .swiper-button-prev {
    bottom: 0;
    top: unset;
    background: #FBF0E512;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    z-index: 11;
    &::after {
      color: white;
      font-size: 14px;
    }
  }
  .swiper-button-next {
    bottom: 0;
    top: unset;
    background: #FBF0E512;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    z-index: 11;
    &::after {
      color: white;
      font-size: 14px;
    }
  }
}

.more-odyssee-swiper {
  .swiper-wrapper {
    //padding: 0 20px
  }
  .swiper-slide {
    width: min(860px, 90vw) !important;
    height: auto;
  }
  .swiper-pagination {
    .swiper-pagination-bullet {
      background: #FFFFFF;
    }
  }
  .swiper-button-prev {
    bottom: 0;
    top: unset;
    background: #FBF0E512;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    z-index: 11;
    &::after {
      color: white;
      font-size: 14px;
    }
  }
  .swiper-button-next {
    bottom: 0;
    top: unset;
    background: #FBF0E512;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    z-index: 11;
    &::after {
      color: white;
      font-size: 14px;
    }
  }
}
