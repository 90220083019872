#root {
    width: 100%;
    height: 100%;
}

@media (min-width: 1025px) {
    body {
        background-image: url("./resources/images/svg/BackgroundSiteGroup.svg");
        background-size: cover;
        background-attachment: fixed;
    }
}

@media (min-width: 600px) {
    .content-background {
        background-image: url("./resources/images/svg/BackgroundSiteGroup.svg") !important;
        background-size: cover !important;
        background-attachment: fixed !important;
    }
}

@media (min-width: 481px) and (max-width: 1024px) {
    .content-background {
        background-image: none !important;
        background: linear-gradient(90deg, #fefafa 0%, #fdebee 10%, #fefafa 56%, #fefaf3 93%, #fefafa 100%) !important;
    }
}

@media (max-width: 480px) {
    .content-background {
        background-image: none !important;
        background: linear-gradient(90deg, #fefafa 0%, #fdebee 10%, #fefafa 56%, #fefaf3 93%, #fefafa 100%) !important;
    }
}

.App {
}


.sidebar-list-background {
    background-image: url("./resources/images/svg/FondMenu.svg") !important;
    background-size: cover !important;
    background-attachment: fixed !important;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.MuiAutocomplete-popper li {
    color: #0f1d2e !important;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@font-face {
    font-family: "Branding Semibold";
    src: local("Branding Semibold"),
    url("./resources/fonts/branding-2-cufonfonts/Branding Semibold.otf") format("truetype");
}



@font-face {
    font-family: "Branding Bold";
    src: local("Branding Bold"), url("./resources/fonts/branding-2-cufonfonts/Branding Bold.otf") format("truetype");
}

@font-face {
    font-family: "Branding Medium";
    src: local("Branding Medium"), url("./resources/fonts/branding-2-cufonfonts/Branding Medium.otf") format("truetype");
}



@font-face {
    font-family: "Yeseva One";
    src: local("Yeseva One"),
    url("./resources/fonts/YesevaOne-Regular.ttf") format("truetype");
}


.CircularProgressbar .CircularProgressbar-path {
    stroke: url(#odyssee-circle) !important;
}

.CircularProgressbar .CircularProgressbar-trail {
    stroke: rgba(199, 196, 222, 0.24) !important;
}

@font-face {
    font-family: "Branding";
    src: url("./resources/fonts/branding-2-cufonfonts/Branding Medium.otf") format("truetype");
    font-weight: 500;
}

@font-face {
    font-family: "Branding";
    src: url("./resources/fonts/branding-2-cufonfonts/Branding Medium Italic.otf") format("truetype");
    font-style: italic;
    font-weight: 500;
}

@font-face {
    font-family: "Branding";
    src: url("./resources/fonts/branding-2-cufonfonts/Branding Semibold.otf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Branding";
    src: url("./resources/fonts/branding-2-cufonfonts/Branding Semibold Italic.otf") format("truetype");
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: "Branding";
    src: url("./resources/fonts/branding-2-cufonfonts/Branding Bold.otf") format("truetype");
    font-weight: 700;
}
